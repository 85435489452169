// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import {API_URL} from "../services"

export const fetchWomers = createAsyncThunk('womer/fetchWomers', async () => {
  const response = await axios.get(`${API_URL}/mentors/womers`)

  if (response.status === 200) {
    return response.data
  }
  return []
})

export const fetchWomer = createAsyncThunk('womer/fetchWomer', async (id) => {
  const response = await axios.get(`${API_URL}/mentors/womers/${id}`)

  if (response.status === 200) {
    return response.data.data
  }
  return {}
})

export const womersSlice = createSlice({
  name: 'womer',
  initialState: {
    womers: {
      data: []
    },
    womer: {}
  },
  reducers: {
    handleWomers: (state, action) => {
      state.womers = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchWomers.fulfilled, (state, action) => {
        state.womers = action.payload
      })
      .addCase(fetchWomer.fulfilled, (state, action) => {
        state.womer = action.payload
      })
  }
})

export const {handleWomers} = womersSlice.actions

export default womersSlice.reducer
