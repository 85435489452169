// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'
import axios from 'axios'
import { API_URL } from '../services'

const config = useJwt.jwtConfig

const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

export const initialState = {
  userData: initialUser()
}

export const getUser = createAsyncThunk('authentication/me', async () => {
  const response = await axios.post(`${API_URL}/userSettings`)

  return response.data
})

export const updateUser = createAsyncThunk('authentication/update', async (data) => {
  const response = await axios.put(`${API_URL}/updateSettings`, data)

  return response.data
})

export const deleteUpload = createAsyncThunk('authentication/deleteUpload', async (data) => {
  const response = await axios.delete(`${API_URL}/deleteFile/${data.id}/${data.field}`)

  return response.data
})

export const upload = createAsyncThunk('user/upload', async ({ data, field }) => {
  const response = await axios.post(`${API_URL}/upload`, data, {
    headers: { 'Content-Type': 'multipart/form-data' }
  })

  return {
    field,
    data: response.data
  }
})

export const authSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload
      state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
      state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      localStorage.setItem('userData', JSON.stringify(action.payload))
      localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload.accessToken))
    },
    handleLogout: state => {
      state.userData = {}
      state[config.storageTokenKeyName] = null
      state[config.storageRefreshTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem(config.storageTokenKeyName)
      localStorage.removeItem(config.storageRefreshTokenKeyName)
    },
    handleUserUpdate: (state, action) => {
      state.userData.firstname = action.payload.firstname || state.userData.firstname
      state.userData.lastname = action.payload.lastname || state.userData.lastname
      state.userData.email = action.payload.email || state.userData.email
      state.userData.phone = action.payload.phone || state.userData.phone
      state.userData.address = action.payload.address || state.userData.address
      state.userData.informations.linkedin = action.payload.informations.linkedin || state.userData.informations.linkedin
      state.userData.informations.company = action.payload.informations.company || state.userData.informations.company
      state.userData.informations.biography = action.payload.informations.biography || state.userData.informations.biography
      state.userData.informations.job = action.payload.informations.job || state.userData.informations.job
      state.userData.informations.project = action.payload.informations.project || state.userData.informations.project
      localStorage.setItem('userData', JSON.stringify(state.userData))
    }
  },
  extraReducers: builder => {
    builder
      .addCase(updateUser.fulfilled, (state, action) => {
        state.userData = {
          ...state.userData,
          ...action.payload
        }

        localStorage.setItem('userData', JSON.stringify(state.userData))
      })
  }
})

export const { handleLogin, handleLogout, handleUserUpdate } = authSlice.actions

export default authSlice.reducer
