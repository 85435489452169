// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import {API_URL} from "../services"

export const initialState = {
  visibilities: []
}

export const getVisibilities = createAsyncThunk('visibilitys/visibilities', async () => {
  const response = await axios.get(`${API_URL}/visibilities`)

  return response.data.data
})

export const visibilitySlice = createSlice({
  name: 'visibility',
  initialState,
  reducers: {
    handleVisibilities: (state, action) => {
      state.visibilities = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getVisibilities.fulfilled, (state, action) => {
        state.visibilities = action.payload
      })
  }
})

export const {handleVisibilities} = visibilitySlice.actions

export default visibilitySlice.reducer
