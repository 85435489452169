// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import {API_URL} from '@src/services'

export const fetchEvents = createAsyncThunk('appCalendar/fetchEvents', async (id) => {
  const response = await axios.get(`${API_URL}/mentors/${id}?populate[0]=timeSlots`)

  return response.data.data.attributes.timeSlots
})

export const addEvent = createAsyncThunk('appCalendar/addEvent',
  async (event, {dispatch, getState}) => {
    const state = getState()
    const {id} = state.auth.userData.mentor
    let errors = null

    const events = await state.calendar.events

    await axios.put(`${API_URL}/mentors/${id}`, {
      data: {
        timeSlots: [
          ...events,
          {
            ...event,
            date: event.date,
            start: `${event.start}:00`,
            end: `${event.end}:00`,
            __component: 'calendar.time-slot'
          }
        ]
      }
    }).catch(e => {
      errors = e.response.data
    })

    if (errors) {
      return errors
    }

    await dispatch(fetchEvents(id))

    return event
  })

export const updateEvent = createAsyncThunk('appCalendar/updateEvent',
  async (event, {dispatch, getState}) => {
    const state = getState()
    const {id} = state.auth.userData.mentor
    let errors = null

    const events = await state.calendar.events

    const timeSlots = events.map(e => {
      if (e.id === +event.id) {
        return {
          ...event,
          date: new Date(event.date),
          start: `${event.start}:00`,
          end: `${event.end}:00`,
          __component: 'calendar.time-slot'
        }
      }
      return e
    })

    await axios.put(`${API_URL}/mentors/${id}`, {
      data: {
        timeSlots
      }
    }).catch(e => {
      errors = e.response.data
    })

    if (errors) {
      return errors
    }

    await dispatch(fetchEvents(id))

    return event
  })

export const removeEvent = createAsyncThunk('appCalendar/removeEvent',
  async (event, {dispatch, getState}) => {
    const state = getState()
    const {id} = state.auth.userData.mentor

    const events = await state.calendar.events

    const timeSlots = events.filter(e => e.id !== +event)

    await axios.put(`${API_URL}/mentors/${id}`, {
      data: {
        timeSlots
      }
    })

    await dispatch(fetchEvents(id))

    return event
  })

export const appCalendarSlice = createSlice({
  name: 'appCalendar',
  initialState: {
    events: [],
    selectedEvent: {}
  },
  reducers: {
    selectEvent: (state, action) => {
      state.selectedEvent = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.events = action.payload
      })
  }
})

export const {selectEvent} = appCalendarSlice.actions

export default appCalendarSlice.reducer
