// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios"
import {API_URL} from "../services"

const initialState = {
  data: []
}

export const getDisponibility = createAsyncThunk('disponibilities/get', async () => {
  const response = await axios.get(`${API_URL}/disponibilities`)

  return response.data.data
})

export const disponibilitySlice = createSlice({
  name: 'disponibility',
  initialState,
  reducers: {
    handleDisponibility: (state, action) => {
      state.data = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getDisponibility.fulfilled, (state, action) => {
        state.data = action.payload
      })
  }
})

export const {handleDisponibility} = disponibilitySlice.actions

export default disponibilitySlice.reducer
