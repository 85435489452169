// ** Auth Endpoints
import {API_URL} from '@src/services'

export default {
  loginEndpoint: `${API_URL}/login`,
  registerEndpoint: '/jwt/register',
  refreshEndpoint: `${API_URL}/refreshToken`,
  logoutEndpoint: '/jwt/logout',
  autoLoginEndpoint: `${API_URL}/autologin`,
  resetPasswordEndpoint: `${API_URL}/auth/reset-password`,
  forgotPasswordEndpoint: `${API_URL}/auth/forgot-password`,

  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
}
