// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios"
import {stringify} from "qs"
import {API_URL} from "../services"

const initialState = {
  data: [],
  currentClass: {},
  nextClasses: [],
  prevClasses: {},
  token: '',
  credits: {
    free: 0,
    mentors: [],
    temp_mentors: 0,
    products: []
  },
  bookedClass: {
    mentor: null,
    womer: null,
    answers: [],
    timeslot: {}
  }
}

// eslint-disable-next-line no-unused-vars
export const getClasses = createAsyncThunk('classes/getClasses', async ({page, pageSize}) => {
  /*const query = stringify({
    pagination: {
      page,
      pageSize
    }
  }, {
    encodeValuesOnly: true
  })

  const response = await axios.get(`${API_URL}/classes?${query}`)*/
  const response = await axios.get(`${API_URL}/classes`)

  return response.data
})

// eslint-disable-next-line no-unused-vars
export const getNextClasses = createAsyncThunk('classes/getNextClasses', async ({page, pageSize}) => {
  const query = stringify({
    filters: {
      timeslot: {
        date: {
          $gte: new Date()
        }
      },
      status: 'booked',
      mentor: {
        id: {
          $notNull: true
        }
      }
    },
    sort: {
      timeslot: {
        date: 'asc',
        start: 'asc'
      }
    }
    /*, pagination: {
      page,
      pageSize
    }*/
  }, {
    encodeValuesOnly: true
  })

  const response = await axios.get(`${API_URL}/classes?${query}`)

  return response.data
})

// eslint-disable-next-line no-unused-vars
export const getPrevClasses = createAsyncThunk('classes/getPrevClasses', async ({page, pageSize}) => {
  const query = stringify({
    filters: {
      timeslot: {
        date: {
          $lte: new Date()
        }
      },
      status: 'finish',
      mentor: {
        id: {
          $notNull: true
        }
      }
    },
    sort: {
      timeslot: {
        date: 'desc',
        start: 'desc'
      }
    }
    /*, pagination: {
      page,
      pageSize
    }*/
  }, {
    encodeValuesOnly: true
  })

  const response = await axios.get(`${API_URL}/classes?${query}`)

  return response.data
})

export const getClasse = createAsyncThunk('classes/getClasse', async (id) => {
  const response = await axios.get(`${API_URL}/classes/${id}`)

  return response.data
})

export const getToken = createAsyncThunk('classes/getToken', async () => {
  const response = await axios.get(`${API_URL}/classes/token`)

  return response.data.data.token
})

export const createClass = createAsyncThunk('classes/create', async (data, {getState, rejectWithValue}) => {
  const bookedClass = getState().classes.bookedClass
  const answers = bookedClass.answers.map(answer => ({answer}))

  try {
    const response = await axios.post(`${API_URL}/classes`, {
      data: {...bookedClass, answers, choice: data.choice}
    })

    return response.data.data
  } catch (error) {
    return rejectWithValue(error.response.data.error.message)
  }
})

export const updateClass = createAsyncThunk('classes/update', async (data) => {
  const response = await axios.put(`${API_URL}/classes/${data.id}`, {data})

  return response.data.data
})

export const bookClass = createAsyncThunk('classes/book', async (data) => data)

export const getFreeCredits = createAsyncThunk('classes/getFreeCredits', async () => {
  const response = await axios.get(`${API_URL}/credit/free`)

  if (response.status === 200) {
    return response.data.data.attributes.credits
  }
  return 0
})

export const getAllCredits = createAsyncThunk('classes/getAllCredits', async () => {
  const response = await axios.get(`${API_URL}/credit/all`)

  if (response.status === 200) {
    return response.data.data.attributes
  }
  return 0
})

export const assignCreditsToMentor = createAsyncThunk('classes/assignCreditsToMentor', async (data) => {
  const response = await axios.put(`${API_URL}/credit/mentors/${data.id}`, {data})

  if (response.status === 200) {
    return response.data
  }
  return 0
})

export const getStripeProducts = createAsyncThunk('classes/getStripeProducts', async () => {
  const url = new URL(API_URL)

  const response = await axios.get(`${url.protocol}//${url.host}/strapi-stripe/getProduct/0/5/name/asc`)

  return response.data.res
})

export const classeSlice = createSlice({
  name: 'classes',
  initialState,
  reducers: {
    handleBookClass: (state, action) => {
      state.bookedClass = action.payload
    },
    handleAddAnswer: (state, action) => {
      console.log(action.payload)

      state.bookedClass.answers[action.payload.step - 1] = action.payload.answer
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getClasses.fulfilled, (state, action) => {
        state.data = action.payload
      })
      .addCase(getNextClasses.fulfilled, (state, action) => {
        state.nextClasses = action.payload
      })
      .addCase(getPrevClasses.fulfilled, (state, action) => {
        state.prevClasses = action.payload
      })
      .addCase(getClasse.fulfilled, (state, action) => {
        state.currentClass = action.payload
      })
      .addCase(getToken.fulfilled, (state, action) => {
        state.token = action.payload
      })
      .addCase(updateClass.fulfilled, (state, action) => {
        state.data = [...state.data, action.payload]
      })
      .addCase(bookClass.fulfilled, (state, action) => {
        state.bookedClass = {...state.bookedClass, ...action.payload}
      })
      .addCase(getFreeCredits.fulfilled, (state, action) => {
        state.credits.free = action.payload
      })
      .addCase(getAllCredits.fulfilled, (state, action) => {
        state.credits.free = action.payload.credits
        state.credits.mentors = action.payload.mentor_credits
        state.credits.temp_mentors = action.payload.temp_mentor_credits
      })
      .addCase(assignCreditsToMentor.fulfilled, (state, action) => {
        state.credits.mentors.push(action.payload)
        state.credits.temp_mentors = 0
      })
      .addCase(getStripeProducts.fulfilled, (state, action) => {
        state.credits.products = action.payload
      })
  }
})

export const {handleBookClass, handleAddAnswer} = classeSlice.actions

export default classeSlice.reducer
