import React from 'react'
import {Button, Card, CardBody, Col, Row} from 'reactstrap'
import errorImg from '../../../../assets/images/errors/500.svg'
import {Link} from "react-router-dom"

const Error500 = () => {
  const refreshPage = () => {
    window.location.reload()
  }

    return (
      <Row className="m-0 vh-100">
        <Col sm="12">
          <Card className="auth-card bg-transparent shadow-none rounded-0 mb-0 w-100">
            <CardBody className="text-center">
              <div className="mb-4">
                <img
                  width={300}
                  src={errorImg}
                  alt=""
                  className="img-fluid align-self-center"
                />
              </div>
              <h1 className="font-large-2 m-4">Oh non !</h1>
              <p>La connexion avec notre serveur a été interrompu. Veuillez réessayer plus tard.</p>
              <Row className="mt-4">
                <Col>
                  <Button.Ripple
                    onClick={refreshPage}
                    color="primary"
                    size="lg"
                  >
                    Réessayer
                  </Button.Ripple>
                </Col>
                <Col>
                  <Button.Ripple
                    tag={Link}
                    to='/'
                    color='secondary'
                    size="lg"
                    className='btn-sm-block'>
                    Retourner à l&apos;accueil
                  </Button.Ripple>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    )
}

export default Error500
