// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import user from './user'
import specialties from './specialties'
import sectors from './sectors'
import visibility from './visibility'
import events from '../views/mentors/profile/planning/calendar/store'
import users from '@src/views/apps/user/store'
import invoice from '@src/views/apps/invoice/store'
import calendar from '@src/views/apps/calendar/store'
import timeSlot from './mentors'
import disponibility from './disponibility'
import classes from './classes'
import womers from './womers'
import offers from './offers'

import { combineReducers } from 'redux'

const rootReducer = {
  auth,
  user,
  specialties,
  sectors,
  navbar,
  layout,
  invoice,
  calendar,
  users,
  timeSlot,
  disponibility,
  visibility,
  classes,
  events,
  womers,
  offers
}

export default combineReducers(rootReducer)
