import axios from 'axios'
import jwtDefaultConfig from './jwtDefaultConfig'
import {decode} from "jsonwebtoken"
import {store} from '@store/store'
import { handleLogout } from '@store/authentication'

export default class JwtService {
  constructor(jwtOverrideConfig) {
    // ** For Refreshing Token
    this.isAlreadyFetchingAccessToken = false

    // ** For Refreshing Token
    this.subscribers = []

    this.jwtConfig = {...jwtDefaultConfig, ...this.jwtConfig, ...jwtOverrideConfig}

    // ** Request Interceptor
    axios.interceptors.request.use(
      config => {
        if (new URL(config.url).pathname !== '/api/refreshToken') {
          // ** Get token from localStorage
          let accessToken = this.getToken()

          if (accessToken) {
            accessToken = accessToken.replace(/"/g, '')
          }

          // ** If token is present add it to request's Authorization Header
          if (decode(accessToken, {})) {
            // ** eslint-disable-next-line no-param-reassign
            config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
          }
        }
        return config
      },
      error => Promise.reject(error)
    )

    // ** Add request/response interceptor
    axios.interceptors.response.use(
      response => response,
      error => {
        // ** const { config, response: { status } } = error
        const {config, response} = error
        const originalRequest = config

        // ** if (status === 401) {
        if (response && response.status === 401) {
          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // ** Update accessToken in localStorage
              this.setToken(r.data.jwt)

              this.onAccessTokenFetched(r.data.jwt)

              location.reload()
            })
              .catch(() => {
                this.isAlreadyFetchingAccessToken = false
                store.dispatch(handleLogout())
              })
          }
          return new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // ** Make sure to assign accessToken according to your response.
              // ** Check: https://pixinvent.ticksy.com/ticket/2413870
              // ** Change Authorization header
              originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
              resolve(axios(originalRequest))
            })
          })
        }
        return Promise.reject(error)
      }
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  login(...args) {
    return axios.post(this.jwtConfig.loginEndpoint, ...args)
  }

  autoLogin(...args) {
    return axios.post(this.jwtConfig.autoLoginEndpoint, ...args)
  }

  register(...args) {
    return axios.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return axios.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getToken().replace(/"/g, '')
    }, {
      "Access-Control-Allow-Credentials": true,
      withCredentials: true
    })
  }
}
