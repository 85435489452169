// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import {UPDATE, USER} from "../services/users"

export const initialState = {
  lastname: '',
  firstname: '',
  email: '',
  password: '',
  confirmPassword: '',
  phone: '',
  informations: {
    job: '',
    company: '',
    biography: '',
    linkedin: '',
    project: ''
  },
  address: {
    line1: '',
    line2: '',
    postal_code: '',
    state: '',
    city: ''
  },
  specialties: []
}

export const getUser = createAsyncThunk('users/me', async (token) => {
  const response = await axios.post(USER, {token})

  return {
    user: response.data
  }
})

export const updateUser = createAsyncThunk('users/update', async (data) => {
  const response = await axios.patch(`${UPDATE}/${data.token}`, data)

  return response.data
})

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    handleUser: (state, action) => {
      state.user = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getUser.fulfilled, (state, action) => {
        const {
          email,
          firstname,
          lastname,
          phone,
          specialties
        } = action.payload.user

        state.email = email
        state.firstname = firstname
        state.lastname = lastname
        state.phone = phone
        state.specialties = specialties

        if (action.payload.user.informations) {
          const {
            job,
            company,
            biography,
            project
          } = action.payload.user.informations

          state.informations = {
            job,
            company,
            biography,
            project
          }
        }
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.user = {...state, user: action.payload}
      })
  }
})

export const {handleUser} = userSlice.actions

export default userSlice.reducer
