// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import {ENDPOINT} from "../services/specialties"

const initialState = {
  specialties: []
}

export const getSpecialties = createAsyncThunk('specialties/get', async () => {
  const response = await axios.get(ENDPOINT)

  return {
    specialties: response.data.data
  }
})

export const specialitySlice = createSlice({
  name: 'specialties',
  initialState,
  reducers: {
    handleSpecialties: (state, action) => {
      state.specialties = action.payload.specialties
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getSpecialties.fulfilled, (state, action) => {
        state.specialties = action.payload.specialties
      })
  }
})

export const {handleSpecialties} = specialitySlice.actions

export default specialitySlice.reducer
