import axios from 'axios'

class MockAdapter {
  constructor (instance) {
    this.instance = instance
  }

  onGet() {
    return {reply: () => null}
  }

  onPost() {
    return {reply: () => null}
  }

  onDelete() {
    return {reply: () => null}
  }

  onAny() {
    return {passThrough: () => null}
  }
}

const mock = new MockAdapter(axios)

export default mock
