// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { API_URL } from '@src/services'

const initialState = {
  data: []
}

export const getOffers = createAsyncThunk('offers/getOffers', async ({ role }) => {
  const response = await axios.get(`${API_URL}/offers?fields[0]=title&fields[1]=description&fields[2]=updatedAt&filters[roles][type][$eq]=${role}&populate[roles][fields][0]=type&populate[cta]=true`)

  return response.data
})

export const offerSlice = createSlice({
  name: 'offers',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(getOffers.fulfilled, (state, action) => {
        state.data = action.payload.data
      })
  }
})

export default offerSlice.reducer
