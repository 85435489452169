// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import {API_URL} from "../services"

export const fetchMentors = createAsyncThunk('mentor/fetchMentors', async () => {
  const response = await axios.get(`${API_URL}/womers/mentors`)

  if (response.status === 200) {
    return response.data.data
  }
  return []
})

export const fetchMentor = createAsyncThunk('mentor/fetchMentor', async (id) => {
  const response = await axios.get(`${API_URL}/womers/mentors/${id}`)

  if (response.status === 200) {
    return response.data.data
  }
  return {}
})

export const hasNextDisponibilities = createAsyncThunk('mentor/hasDisponibilities', async () => {
  const response = await axios.get(`${API_URL}/mentors/hasDisponibilities`)

  if (response.status === 200) {
    return response.data.data
  }
  return {}
})

export const mentorSlice = createSlice({
  name: 'mentor',
  initialState: {
    mentors: [],
    mentor: {},
    hasDisponibilities: false
  },
  reducers: {
    handleMentors: (state, action) => {
      state.mentors = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(fetchMentors.fulfilled, (state, action) => {
        state.mentors = action.payload
      })
      .addCase(fetchMentor.fulfilled, (state, action) => {
        state.mentor = action.payload
      })
      .addCase(hasNextDisponibilities.fulfilled, ((state, action) => {
        state.hasDisponibilities = action.payload
      }))
  }
})

export const {handleMentors} = mentorSlice.actions

export default mentorSlice.reducer
