// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from "axios"
import {API_URL} from "../services"

const initialState = {
  data: []
}

export const getSectors = createAsyncThunk('sectors/get', async () => {
  const response = await axios.get(`${API_URL}/sectors`)

  return response.data.data
})

export const createSectors = createAsyncThunk('sectors/create', async (data) => {
  const response = await axios.post(`${API_URL}/sectors`, data)

  return response.data.data
})

export const updateSectors = createAsyncThunk('sectors/update', async ({id, data}) => {
  const response = await axios.put(`${API_URL}/sectors/${id}`, {data})

  return response.data.data
})

export const sectorSlice = createSlice({
  name: 'sectors',
  initialState,
  reducers: {
    handleSectors: (state, action) => {
      state.data = action.payload
    }
  },
  extraReducers: builder => {
    builder
      .addCase(getSectors.fulfilled, (state, action) => {
        state.data = action.payload
      })
      .addCase(createSectors.fulfilled, (state, action) => {
        state.data = [...state.data, action.payload]
      })
      .addCase(updateSectors.fulfilled, (state, action) => {
        state.data = [...state.data, action.payload]
      })
  }
})

export const {handleSectors} = sectorSlice.actions

export default sectorSlice.reducer
